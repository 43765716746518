@keyframes progress {
  0% {
    transform: rotate(25deg) scaleY(2);
    left: -140%;
  }
  100% {
    transform: rotate(25deg) scaleY(2);
    left: 140%;
  }
}
.load {
  background: #999;
  overflow: hidden;
  position: relative;
}
.load div {
  animation: progress 1.4s ease-in-out infinite;
  background-image: linear-gradient(90deg, #999, #aaa, #999);
  height: 100%;
  min-height: 6em;
  width: 260px;
  position: absolute;
  top: 0;
}
